import React, { useEffect, useState } from 'react';
import { DownOutlined, InboxOutlined, LogoutOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Layout, Button, Table, Select, Form, Input, Modal, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../config';
import styles from './index.module.css'
import userIcon from '../../icons/user-icon.png'
import { RequiredStar } from '../../components/RequiredStar';
import { role } from '../../components/helper';
const { Option } = Select;
const HeaderComponenet = () => {
  const { Header: AntHeader } = Layout;

  const [requestsCount, setRequestsCount] = useState(5)

  const navigate = useNavigate();
  const [userModal, setUserModal] = useState(false)

  const handleMenuClick = async (e) => {
    if (e.key === '1') {
      navigate('/providers');
    }
    if (e.key === '3') {
      navigate('/users');
      // setUserModal(true)
    }
    if (e.key === '4') {
      localStorage.clear();
      navigate('/login');
    }
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('user'))) {
      localStorage.clear();
      navigate('/login');
    }
  }, [])


  const menu = (
    <Menu
      onClick={handleMenuClick}
      className={styles.menu}
      items={[
        role === 'Super_Admin' ||
          role === 'Admin' ?
          {
            label: <h4 style={{ fontSize: '14px', marginBottom: '0px' }}>Users</h4>,
            key: '3',
            icon: <UsergroupAddOutlined style={{ fontSize: '18px' }} />,
          }
          : null,
        {
          label: <h4 style={{ fontSize: '14px', marginBottom: '0px' }}>Logout</h4>,
          key: '4',
          icon: <LogoutOutlined style={{ fontSize: '14px', marginLeft: '3px' }} />,
        },
      ]}
    />
  );

  const UserModal = () => {
    const [users, setUsers] = useState([])
    const [newUser, setNewUser] = useState(false)
    const [success, setSuccess] = useState(false)
    const [msg, setMsg] = useState('')

    const { Option } = Select;
    useEffect(() => {
      (async () => {
        const response = fetch(`${baseURL}user/profiles/PAS?pageSize=100`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`
          }
        })
          .then(res => res.json())
          .then(res => {
            if (res.api.responseCode == 2040) {
              setUsers(res.result.content)
            }
          })

      })()
    }, [success])

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: 60,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: 90,
      },
      {
        title: 'Phone',
        dataIndex: 'contact_number',
        width: 70,
      },
      {
        title: 'Role',
        dataIndex: 'account',
        width: 50,
        render: account => account.role.replaceAll('_', ' ')
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 40,
        render: status => status === 'Active' ?
          <div className={styles.statusContainer}>
            <div className={styles.statusDot}></div>
            <p> Active</p>
          </div> :
          <div className={styles.statusContainer}>
            <div className={styles.statusDot}></div>
            <p> Inactive</p>
          </div>
      },
      {
        title: 'Action',
        dataIndex: '',
        width: 40,
        render: user => <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Button disabled={user.status === 'Active'} type="primary" size="small" style={{ borderRadius: '15px' }} onClick={() => activateUser(user.user_id)}>Activate</Button>
        </div>
      },

    ];
    const activateUser = async (userID) => {
      const response = await fetch(`${baseURL}user/${userID}/activate`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`
        },
      })
        .then(res => res.json())
        .then(res => {
          if (res.api.responseCode === 2030) {
            setSuccess(true)
          }
        })
    }
    const addNewUser = async (val) => {
      const dataup = {
        contact_number: val.phone,
        email: val.email,
        first_name: val.first_name,
        last_name: val.last_name,
        ref_access: 'PAS',
        role: val.role,
      }
      const response = await fetch(`${baseURL}user`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`
        },
        body: JSON.stringify(dataup)
      })
        .then(res => res.json())
        .then(res => {
          if (res.api.responseCode === 2010) {
            setMsg('Successfully added user.')
            setSuccess(true)
          } else {
            setMsg(res.api.response.replaceAll('_', ' '))
            setSuccess(true)
          }
        })
    }
    return (
      <Modal
        centered
        visible={userModal}
        footer={null}
        width={!newUser ? '75%' : '35%'}
        style={{
          minWidth: '500px'
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <h2 style={{ position: 'absolute', top: 5, left: 5 }}>Manage Users</h2>
          <Button

            onClick={() => setUserModal(false)}
            style={{ position: 'absolute', top: 5, right: 5, borderRadius: '15px' }}
          >X</Button>
          {newUser ?

            (
              !success ?
                <Form onFinish={addNewUser} style={{ width: '50%' }}>
                  <div className={styles.sectionCard} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', }}>
                    <div className={styles.cardHeader}>
                      <h2 style={{ fontWeight: 'bold' }}>Add New User</h2>
                    </div>
                    <div className={styles.divider}> </div>
                    <div style={{ width: '48%', marginBottom: '-10px' }}>
                      <h4 className={styles.formLabel}>First Name <RequiredStar /> </h4>
                      <Form.Item
                        name="first_name"
                        rules={[{ required: true, message: 'Please Enter First Name' }]}
                      >
                        <Input placeholder="First Name" className={styles.formInput} />
                      </Form.Item>
                    </div>
                    <div style={{ width: '48%', marginBottom: '-10px' }}>
                      <h4 className={styles.formLabel}>Last Name<RequiredStar /></h4>
                      <Form.Item
                        name="last_name"
                        rules={[{ required: true, message: 'Please Enter Last Name' }]}
                      >
                        <Input placeholder="Last Name" className={styles.formInput} />
                      </Form.Item>
                    </div>
                    <h4 className={styles.formLabel}>Email Address<RequiredStar /></h4>
                    <Form.Item
                      name="email"
                      style={{ width: '100%', marginBottom: '10px' }}
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Email'
                        }
                        , {
                          type: 'email',
                          message: 'Please Enter Valid Email'
                        }
                      ]}
                    >
                      <Input placeholder="Email Address" className={styles.formInput} />
                    </Form.Item>
                    <h4 className={styles.formLabel}>Phone Number</h4>
                    <Form.Item
                      name="contact_number"
                      style={{ width: '100%', marginBottom: '10px' }}
                    >
                      <Input placeholder="Phone Number" type="number" className={styles.formInput} />
                    </Form.Item>

                    <h4 className={styles.formLabel}>Role <RequiredStar /></h4>
                    <Form.Item
                      name="role"
                      rules={[{ required: true, message: 'Please Select a Role' }]}
                      style={{ width: '100%', marginBottom: '10px' }}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        dropdownStyle={{ borderRadius: '25px' }}
                        placeholder='Select a Role'
                      >
                        <Option value="Super_Admin">
                          Super Admin
                        </Option>
                        <Option value="Admin">
                          Admin
                        </Option>
                        <Option value="Operator">
                          Operator
                        </Option>

                      </Select>
                    </Form.Item>
                  </div>
                </Form> :
                <div style={{ width: '100%', gap: '25px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-end', alignItems: 'flex-start', margin: '25px', marginTop: '75px' }}>
                  <h3 style={{ textAlign: 'left', fontSize: '18px', fontWeight: '500', color: '#3a3a3aaa' }}>{msg}</h3>
                  <Button type='primary' style={{ borderRadius: '15px' }}
                    onClick={() => { setNewUser(false); setSuccess(false) }}
                  >Proceed</Button>
                </div>
            )
            :

            <div style={{ padding: '25px', paddingTop: '75px', minWidth: '450px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '25px' }}>
              <Table
                columns={columns}
                dataSource={users}
                scroll={{
                  y: '450px',
                }}
                rowKey="user_id"
                pagination={false}
                style={{
                  borderRadius: '15px', overflow: 'hidden', width: '100%',
                }}
              />
              <Button
                style={{ borderRadius: '15px', width: '150px' }}
                onClick={() => setNewUser(true)}
              >Add New User</Button>
            </div>
          }

        </div>
      </Modal >
    )
  }

  let user = JSON.parse(localStorage.getItem('user'))?.first_name;

  return (
    <AntHeader className={styles.headerContainer}>
      {userModal ? <UserModal userModal={userModal} setUserModal={setUserModal} /> : null}
      <Button
        style={{ marginRight: '0px' }}
        className={styles.btnShadow}
        href='mailto:engineering@edencaremedical.com'
      >Get Help</Button>
      {/* <Badge count={5}>
        <Button
          className={styles.btnShadow}
          // style={}
          // type='text'
          href='/update-requests'
        >Requests<InboxOutlined style={{ fontSize: '24px', marginTop: '5px', fontWeight: '300' }} /></Button>
      </Badge> */}
      {/* <Button
        className={styles.btnShadow}
        style={{ padding: '15px' }}
      > */}
      <Dropdown
        className={styles.btnShadow}
        style={{ border: '1px solid black !important', padding: '2px 10px !important' }}
        overlay={menu}
        getPopupContainer={trigger => trigger.parentElement}
        trigger={['click']}
      >
        <div
          className={styles.dropdownBtn}
        >
          <img src={userIcon} style={{ width: 25 }} alt="" />
          <h3 className={styles.userName}>{user}</h3>
          <DownOutlined style={{ fontSize: '14px', marginTop: '2px' }} />
        </div>
      </Dropdown>
      {/* </Button> */}
    </AntHeader>
  );
};

export default HeaderComponenet;
