import styles from './index.module.css'

const StatusDot = ({ status }) => status === 'Active' ? <div className={styles.tableStatusContainer}>
    <div className={styles.tableStatus} style={{ backgroundColor: '#3AB44D' }}></div>
    <p> Active</p>
</div> :
    <div className={styles.tableStatusContainer}>
        <div className={styles.tableStatus} style={{ backgroundColor: '#f87d4e' }}></div>
        <p> Inactive</p>
    </div>

export default StatusDot;