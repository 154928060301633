export const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
};

export const toCamelCase = (str) => {
    return str.replaceAll(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replaceAll(/\s+/g, '');
}

export const checkUppercaseAndNumb = (str) => {
    if (/[^a-zA-Z]/.test(str) && str !== str.toLowerCase()) {
        return true;
    } else {
        return false;
    }
};

import { notification } from 'antd';

export const openNotification = (msg, title) => {
    notification.open({
        message: title,
        description: msg,
        style: {
            borderRadius: '15px',
        },
    });
};


export const role = JSON.parse(localStorage.getItem('user'))?.account.role

import clinic from '../icons/providerTypes/clinic.png'
import dentalClinic from '../icons/providerTypes/dentalClinic.png'
import diagnostic from '../icons/providerTypes/diagnostic.png'
import evacuationService from '../icons/providerTypes/evacuationService.png'
import hospital from '../icons/providerTypes/hospital.png'
import pharmacy from '../icons/providerTypes/pharmacy.png'
import specializedService from '../icons/providerTypes/specializedService.png'
import dialysisCenter from '../icons/providerTypes/dialysis-center.png'

export const providerDefaultLogo = (item) => {
    var logo;
    switch (item?.type) {
        case ('Dental_Clinic'):
            logo = dentalClinic;
            break;
        case ('Diagnostic'):
            logo = diagnostic;
            break;
        case ('Dialysis_Center'):
            logo = dialysisCenter
            break;
        case ('Hospital'):
            logo = hospital;
            break;
        case ('Evacuation_Services'):
            logo = evacuationService;
            break;
        case ('Independent_Clinic'):
            logo = clinic;
            break;
        case ('Pharmacy'):
            logo = pharmacy;
            break;
        case ('Specialized_Services'):
            logo = specializedService;
            break;
    }
    if (item?.documents?.logo?.url) {
        logo = item?.documents?.logo?.url
    }
    return (
        <img src={logo}
            style={{
                maxWidth: '100%',
                maxHeight: '100%',
                verticalAlign: ' middle',

            }} />
    )

}

import providerIcon from '../icons/provider.png'
import employerIcon from '../icons/employers.png'
import wellnessIcon from '../icons/wellness-partner.png'

export const partnerDefaultLogo = (item) => {
    var logo;
    switch (item?.reference) {
        case ('Provider'):
            logo = providerIcon;
            break;
        case ('Employer'):
            logo = employerIcon;
            break;
        case ('Wellness_Partner'):
            logo = wellnessIcon
            break;
    }
    if (item?.documents?.logo?.url) {
        logo = item?.documents?.logo?.url
    }
    return (
        <img src={logo}
            style={{
                maxWidth: '100%',
                maxHeight: '100%',
                verticalAlign: ' middle',

            }} />
    )

}
import { baseURL } from '../config';
import { saveAs } from 'file-saver';

export const downloadProviderSnapshot = async ({ setSnapshotLoading }) => {
    setSnapshotLoading(true)
    await fetch(`${baseURL}pas/onboard/report/provider/snapshot/excel`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`, 'Content-Type': 'application/octect-stream' }
    })
        .then(res => {
            const filename = res.headers.get('Content-Disposition').split('filename=')[1];
            res.blob().then((blob) => saveAs(blob, filename));
        })
        .finally(() => setSnapshotLoading(false))
}