import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../config'
const initialState = {
    loading: false,
    data: [],
    isLoading: false,
    details: {
        provider: null,
        isLoading: false
    },
    property: {
        empty: false,
        first: false,
        last: false,
        page_number: 0,
        page_size: 0,
        sort: {
            empty: false,
            sorted: false,
            unsorted: false
        },
        total_elements: 0,
        total_pages: 0
    }
};

let token = localStorage.getItem('x-auth-token');
export const loadProviders = createAsyncThunk('providers/loadProviders', async ({ page, pageSize, status, sort }) => {
    return await fetch(`${baseURL}pas/provider/profiles?page=${page}&size=${pageSize}${(status && status !== 'All') ? '&status=' + status : ''}${sort ? '&sort=createdTimestamp,' + sort : ''}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => {
            if (res.api.responseCode === 4150) {
                localStorage.clear();
                window.location.reload();
            }
            return (res.result)
        })
        .catch(e => console.log(e))
});

export const searchProviders = createAsyncThunk('providers/searchProviders', async ({ query, page, pageSize }) => {
    return await fetch(`${baseURL}pas/provider/search?query=${query}&page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const getProviderDetails = createAsyncThunk('providers/getProviderDetails', async (providerId) => {
    return await fetch(`${baseURL}pas/provider/${providerId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const onboardProvider = createAsyncThunk('providers/onboardProvider', async (data) => {
    return fetch(`${baseURL}pas/provider/onboard`, {
        method: 'POST',
        headers: {
            "accept": "*/*",
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(e => console.log(e))
});

export const uploadDocs = createAsyncThunk('providers/uploadDocs', async ({ providerId, logo, license, tin, contract, misc, services }) => {
    const data = new FormData();
    data.append('license', license[0])
    data.append('logo', logo[0])
    data.append('tin', tin[0])
    contract.map(item => data.append('contract', item))
    misc.map(item => data.append('misc', item))
    services.map(item => data.append('services', item))

    return fetch(`${baseURL}pas/provider/${providerId}/upload-documents`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('x-auth-token'),
        },
        body: data
    })
        .then(res => res.json())
        .catch(e => console.log(e))
});

export const getProviderUsers = createAsyncThunk('providers/getProviderUsers', async (providerId) => {
    return await fetch(`${baseURL}user/profile/Provider?accessId=${providerId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const addBranch = createAsyncThunk('providers/addBranch', async ({ data, providerId }) => {
    return await fetch(`${baseURL}pas/provider/${providerId}/branch`, {
        method: 'POST',
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const addUser = createAsyncThunk('providers/addUser', async (data) => {
    return await fetch(`${baseURL}user/`, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + localStorage.getItem('x-auth-token')
        },
        body: JSON.stringify({ ...data, ref_access: 'Provider' })
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const ProviderSlice = createSlice({
    name: 'providers',
    initialState: initialState,
    reducers: {
        // loadProviders: (state, action) => {
        //     state.data = action.payload;
        // },
    },
    extraReducers(builder) {
        builder.addCase(loadProviders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadProviders.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(loadProviders.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(searchProviders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchProviders.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(searchProviders.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getProviderDetails.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getProviderDetails.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.provider = action.payload;
        });
        builder.addCase(getProviderDetails.rejected, (state) => {
            state.details.isLoading = false;
        });
        builder.addCase(getProviderUsers.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getProviderUsers.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.users = action.payload;
        });
        builder.addCase(getProviderUsers.rejected, (state) => {
            state.details.isLoading = false;
        });
    },
});

export default ProviderSlice.reducer;
