import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../config'
const initialState = {
    loading: false,
    data: [],
    isLoading: false,
    details: {
        data: null,
        isLoading: false
    },
    property: {
        empty: false,
        first: false,
        last: false,
        page_number: 0,
        page_size: 0,
        sort: {
            empty: false,
            sorted: false,
            unsorted: false
        },
        total_elements: 0,
        total_pages: 0
    }
};

let token = localStorage.getItem('x-auth-token');

export const loadEmployers = createAsyncThunk('employers/loadEmployers', async ({ page, pageSize, status, sort }) => {
    return await fetch(`${baseURL}pas/employer/profiles?page=${page}&size=${pageSize}${(status && status !== 'All') ? '&status=' + status : ''}${sort ? '&sort=createdTimestamp,' + sort : ''}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => {
            if (res.api.responseCode === 4150) {
                localStorage.clear();
                window.location.reload();
            }
            return (res.result)
        })
        .catch(e => console.log(e))
});

export const searchEmployers = createAsyncThunk('employers/searchEmployers', async ({ query, page, pageSize }) => {
    return await fetch(`${baseURL}pas/employer/search?query=${query}&page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const getEmployerDetails = createAsyncThunk('employers/getEmployerDetails', async (employerId) => {
    return await fetch(`${baseURL}pas/employer/${employerId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const onboardEmployer = createAsyncThunk('employers/onboardEmployer', async (data) => {
    return fetch(`${baseURL}pas/employer/onboard`, {
        method: 'POST',
        headers: {
            "accept": "*/*",
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(e => console.log(e))
});

export const uploadDocs = createAsyncThunk('employers/uploadDocs', async ({ employerId, logo, tin, contract, misc }) => {
    const data = new FormData();
    data.append('logo', logo[0])
    data.append('tin', tin[0])
    contract.map(item => data.append('contract', item))
    misc.map(item => data.append('misc', item))

    return fetch(`${baseURL}pas/employer/${employerId}/upload-documents`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('x-auth-token'),
        },
        body: data
    })
        .then(res => res.json())
        .catch(e => console.log(e))
});

export const getEmployerUsers = createAsyncThunk('employers/getEmployerUsers', async (employerId) => {
    return await fetch(`${baseURL}user/profile/Employer?accessId=${employerId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const addUser = createAsyncThunk('employers/addUser', async (data) => {
    return await fetch(`${baseURL}user/`, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + localStorage.getItem('x-auth-token')
        },
        body: JSON.stringify({ ...data, ref_access: 'Employer' })
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});


export const EmployerSlice = createSlice({
    name: 'employers',
    initialState: initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(loadEmployers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadEmployers.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(loadEmployers.rejected, (state) => {
            state.loading = false;
        });


        builder.addCase(searchEmployers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchEmployers.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(searchEmployers.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(getEmployerDetails.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getEmployerDetails.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.employer = action.payload;
        });
        builder.addCase(getEmployerDetails.rejected, (state) => {
            state.details.isLoading = false;
        });

        builder.addCase(getEmployerUsers.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getEmployerUsers.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.users = action.payload;
        });
        builder.addCase(getEmployerUsers.rejected, (state) => {
            state.details.isLoading = false;
        });
    },
});

export default EmployerSlice.reducer;
