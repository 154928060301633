//dev
const devURL = 'https://service.edencaremedical.com/dev/api/v1/';
const qaURL = "https://service.edencaremedical.com/qa/api/v1/";
const uatURL = "https://service.edencaremedical.com/uat/api/v1/";

//prod
const prodURL = 'https://service.edencaremedical.com/prod/api/v1/';

export const baseURL = uatURL
    // process.env.REACT_BRANCH === 'prod' ?
    //     prodURL :
    //     (process.env.REACT_BRANCH === 'qa' ? qaURL :
    //         (process.env.REACT_BRANCH === 'uat' ? uatURL : devURL)
    //     )
